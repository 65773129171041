import {CMSImageMediaType} from "@api/CMS/cms.api";
import Layout from "@components/Layout";
import {GetStaticPaths, GetStaticProps} from "next";
import {FC} from "react";

import {getAllDeliveryGroups, getStoreByIdOnServer} from "../../src/api/Shop/shop.api";
import {CMSState, TCMSState} from "../../src/state/CMS/CMS.state";
import {StoreShiftState} from "../../src/state/Store/Store.state";
import MenuViewPage from "../../src/views/MenuViewPage";
import {getCurrentShift} from "../../src/views/MenuViewPage/components/MenuStateWrapper/MenuStateWrapper";
import {MenuPageProps} from "../../src/views/MenuViewPage/MenuViewPage.interfaces";


interface HeroProps {
  heroImageAltText: string;
  heroLogoAltText: string;
  heroImage: CMSImageMediaType;
  heroImageMobile: CMSImageMediaType;
  heroLogo: CMSImageMediaType;
  heroLogoMobile: CMSImageMediaType;
}

interface HeroVoucher {
  id: number;
  voucherCode: string;
  voucherDescription: string;
  backgroundColor: string;
  textColor: string;
}

export type HeroRestriction = {
  restriction: "NEW_CUSTOMER_ONLY";
} | {
  restriction: "VALID_FOR_SAME_DAY_ONLY";
  resetCookieAfterDays: number;
}

export interface CMSStore {
  store_id: string;
  heroBannerProps: HeroBannerProps[];
  defaultHeroProps: HeroProps & { active: boolean };
}

export interface CMSHeroProps {
  heroImageAltText: string;
  heroLogoAltText: string;
  heroImage: CMSImageMediaType;
  heroLogo: CMSImageMediaType;
  heroImageMobile: CMSImageMediaType;
  heroLogoMobile: CMSImageMediaType;
}

export interface HeroBannerProps {
  id: number;
  startTime: string;
  endTime: string;
  heroProps: HeroProps;
  heroVoucher: HeroVoucher;
  restrictions?: HeroRestriction[]
  active: boolean;
}


export const getStaticPaths = (async () => {
  const deliveryGroups = await getAllDeliveryGroups();
  const paths = deliveryGroups.map((deliveryGroup) => ({params: {city: [deliveryGroup.id]}}));
  return {
    paths: [...paths, {params: {city: undefined}}],
    fallback: false
  }
}) satisfies GetStaticPaths;

const PathToCityMap: Record<string, string> = {
  "hamburg": "Hamburg",
  "berlin": "Berlin",
  "frankfurt": "Frankfurt",
  "cologne": "Köln",
  "dusseldorf": "Düsseldorf"
}
const getCityNameFromPath = (path: string): string => PathToCityMap[path] ?? "";
const menuPriorityProductTags = ["category.top-seller",
  "product.bowl",
  "product.side",
  "product.dessert",
  "product.drink",]
export const getStaticProps = (async (context) => {
  let pathDeliveryGroupId: string = "";
  if (context?.params?.city) {
    pathDeliveryGroupId = typeof (context.params?.city) === "string" ? context.params?.city : context.params?.city[0];
  }
  const activeDeliveryGroupId = pathDeliveryGroupId || "hamburg";
  const preFetchedAvailableDeliveryGroups = await getAllDeliveryGroups();
  const deliveryGroup = preFetchedAvailableDeliveryGroups.find((group) => group.id === activeDeliveryGroupId);
  const store = await getStoreByIdOnServer(deliveryGroup.defaultStore);

  const filteredProducts = store.products.filter(product => product && product?.tags?.some(tag => menuPriorityProductTags.includes(tag)));
  return {
    props: {
      preFetchedProducts: filteredProducts,
      preFetchedAvailableDeliveryGroups,
      activeDeliveryGroupId,
      pathDeliveryGroupId,
      cmsData: {
        abExperiments: [
          {
            experimentName: "ab_cms",
            variants: [{
              name: "A",
              weight: 50
            }, {
              name: "control",
              weight: 50
            }],
            version: 2,
            expiresInDays: 30,
          },
          {
            experimentName: "ab_protein_switch",
            variants: [{
              name: "PROTEIN_A",
              weight: 50
            }, {
              name: "control",
              weight: 50
            }],
            version: 3,
            expiresInDays: 30,
          },
        ],
        dynamicVoucherBox: {
          voucherCopyButtonLabel: "Kopieren",
          voucherCopiedLabel: "Kopiert",
        },
        meta: {
          title: " - Premium Bowl Food. Bio zertifiziert. Lieferservice.",
          description: "Lieferservice & Store in absoluter Top-Qualität ohne Kompromisse. ✅ Clean Food. ✅ Gesund. ✅ Nachhaltig."
        },
        navbar: {
          cartLabel: "Warenkorb"
        },
        menu: {
          featuredLabel: "Featured"
        },
        unavailableProductsModal: {
          title: "Produkte leider ausverkauft",
          body: "Die folgenden Produkte in deinem Warenkorb sind in der Zwischenzeit ausverkauft. Wir müssen diese Produkte leider entfernen.",
          buttonText: "Entfernen und weiter zur Kasse"
        },
        storeInfoSelect: {
          toMenuLabel: "Zum Menü",
          storeDropdownLabel: "Wo?",
          deliveryDropdownLabel: "Wie?",
          scrollLabel: "Scroll zum Menü (Escape)",
          defaultBanners: {
            heroImage: "/assetsV2/images/menu-hero-spring2.jpg",
            heroImageMobile: "/assetsV2/images/menu-hero-spring-mobile.jpg",
            heroLogo: "/assetsV2/images/hero-logo2.png",
            heroLogoMobile: "/assetsV2/images/hero-logo2.png",
            heroImageAltText: "Drei Salatschüsseln mit verschiedenen frischen Zutaten liegen auf einer gestrickten Unterlage. Die Schüsseln sind mit einer Vielzahl von Zutaten gefüllt, darunter Kichererbsen, Süßkartoffeln, Feta-Käse, Birnen, Rote Bete, Tomaten und Salat. Eine Hand hält einen kleinen Behälter mit Dressing. Das Bild ist hell und farbenfroh, zeigt gesunde Zutaten und eine köstliche Mahlzeit.",
            heroLogoAltText: "Stadtsalat Logo",
          },
        },
        loginDropDown: {
          loginHeadline: "Anmeldung",
          registerHeadline: "Registrieren",
          passwordResetHeadline: "Passwort zurücksetzen",
          loginButtonLabel: "Login",
          registerButtonLabel: "Registrieren",
          resetPasswordButtonLabel: "Zurücksetzen",
          firstNameLabel: "Vorname",
          lastNameLabel: "Nachname",
          emailLabel: "E-mail",
          passwordLabel: "Password",
          rememberMeLabel: "Angemeldet bleiben",
          forgotPasswordLabel: "Passwort vergessen?",
          switchToRegisterLabel: "Du hast noch keinen Account?",
          switchToRegisterCTA: "Registrieren",
          switchToLoginLabel: "Du hast bereits einen Account?",
          switchToLoginCTA: "Anmelden",
          cancelPasswordResetCTA: "Abbrechen",
          cancelPasswordResetLabel: "Passwort zurücksetzen abbrechen",
          loginSuccessMessage: "Anmeldung erfolgreich",
          loginFailedMessage: "Bei deiner Anmeldung ist etwas schief gelaufen. Versuche es später erneut",
          loginIncorrectMessage: "Anmeldung fehlgeschlagen. Überprüfe deine Daten und versuche es erneut.",
          resetSuccessMessage: "Wir haben deine Anfrage erhalten. Überprüfe dein E-Mail Postfach",
          resetFailedMessage: "Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
          registrationFailedMessage: "Registrierung fehlgeschlagen. Bitte versuche es später erneut.",
          registrationSuccessMessage: "Registrierung erfolgreich erfolgreich",
          registrationSuccessSecondaryMessage: "Überprüfe dein Postfach und bestätige deine E-Mail-Adresse.",
          emailRequiredErrorMessage: "E-Mail erforderlich",
          emailInvalidErrorMessage: "E-Mail ungültig",
          passwordRequiredErrorMessage: "Passwort benötigt",
          passwordTooShortErrorMessage: "Passwort zu kurz",
          passwordTooLongErrorMessage: "Passwort zu lang",
          firstNameRequiredErrorMessage: "Vorname benötigt",
          firstNameTooShortErrorMessage: "Vorname zu kurz",
          lastNameRequiredErrorMessage: "Nachname benötigt",
          lastNameTooShortErrorMessage: "Nachname zu kurz",
          registerSuccessfulLoginSwitchLabel: "Registrierung Erfolgreich",
          registerSuccessfulLoginSwitchCTA: "Zurück zum Login",
          passwordResetSuccessfulLoginSwitchCTA: "Zurück zum Login",
          agbUrl: "https://stadtsalat.de/datenschutz",
          dataPrivacyUrl: "https://stadtsalat.de/agb"
        },
        accountMenuDropDown: {
          logoutLabel: "Abmelden",
          menuItemsB2B: [
            {title: "Dashboard", url: "/b2b/dashboard/orders", openBlank: false},
            {title: "Rechnung", url: "/b2b/dashboard/invoices", openBlank: false},
            {title: "Meine Daten", url: "/b2b/dashboard/companyData/invoiceAddress", openBlank: false}
          ],
          menuItemsB2C: [
            {title: "Dashboard", url: "/account/dashboard/orders", openBlank: false},
            {title: "Rechnung", url: "/account/dashboard/invoices", openBlank: false},
            {title: "Meine Daten", url: "/account/dashboard/account/daten", openBlank: false}
          ]
        },
        menuDropDown: {
          menuItems: [
            {
              title: "Zusatzstoffe und Allergene",
              url: "https://stadtsalat.de/allergene",
              openBlank: true,
            },
            {
              title: "Unsere Mission",
              url: "https://mission.stadtsalat.de/",
              openBlank: true,
            },
            // {title: "Support", url: "", openBlank: false},
            {title: "B2B", url: "https://stadtsalat.de/b2b", openBlank: true},
            {title: "Jobs", url: "https://stadtsalat.jobs.personio.de", openBlank: true},
          ],
          supportLabel: "Support",
          cartMenuItemLabel: "Warenkorb",
          userLabel: "Konto",
        },
        configurator: {
          saveStraightToCartButtonLabel: "Speichern und zurück in den Warenkorb",
          straightToCartButtonLabel: "Direkt in den Warenkorb",
          continueButtonLabel: "Weiter",
          saveButtonLabel: "Speichern",
          doneButtonLabel: "Fertig",
          customsInfoBannerText: "Du benötigst mindestens **eine Base**, **4 Zutaten** und **ein Dressing** in deiner Bowl",
          bowlNotCompleteText: "Wir empfehlen mindestens 7 Zutaten (4 Standards sind inklusive) und ein Dressing in deiner Bowl",
          bowlHasNoDressingMessage: "Deine Bowl hat kein Dressing. Möchtest du trotzdem fortfahren?",
          jumpToDressingsLabel: "Zu den Dressings",
          editInfoBannerText: "Klicke auf die Zutaten, die du nicht in deiner Bowl möchtest **(max. 3)**",
          nutritionHeaderText: "Nährwerte pro Portion",
          allergensHeaderText: "Allergene",
          hideNutritionText: "Nährwerte ausblenden",
          fatLabel: "Fett",
          saturatedFatLabel: "davon gesättigte Fettsäuren",
          carbLabel: "Kohlenhydrate",
          sugarLabel: "davon Zucker",
          proteinLabel: "Eiweiß",
          saltLabel: "Salz",
          fiberLabel: "Ballaststoffe",
          removeIngredientsText: "Zutaten abwählen",
          nutritionButtonLabel: "Nährwerte & Allergene",
          kcalTooltipText: "Angabe bezieht sich auf das konfigurierte Produkt ohne Dressings, Extras und entfernte Produkte",
          customKcalTooltipText: "Angabe bezieht sich auf das konfigurierte Produkt ohne Extras.",
          backToPreviousStepButtonLabel: "Zurück zu",
          inclusiveText: "inklusive",
          inclusiveAbbreviationText: "inkl.",
          notEnoughBaseIngredientsErrorText: "Bitte wähle mindestens 1 Base aus.",
          saveEditIngredientsButtonLabel: "Fertig",
          editIngredientsHeaderText: "Zutaten bearbeiten",
          bowlNotCompleteHeaderText: "Bowl nicht vollständig",
          continueConfiguringButtonLabel: "Weiter konfigurieren",
          continueToCartButtonLabel: "Trotzdem zum Warenkorb",
          closeModalText: "Willst du deine aktuelle Auswahl verwerfen?",
          closeModalCancelText: "Abbrechen",
          closeModalConfirmText: "Verwerfen",
          unavailableProductsModalText: "Einige Produkte in deiner Bowl sind ausverkauft. Wir müssen diese Produkte leider entfernen.",
          unavailableProductsModalConfirmText: "Entfernen und weiter configurieren",
          upsellModalTitle: "Möchtest du noch ein erfrischendes Getränk oder ein leckeres Dessert?",
          upsellModalToCartLabel: "Zur Kasse",
          unavailableProteinLabel: "Nicht verfügbar",
          proteinPopularLabel: "Popular",
          proteinSoldOutLabel: "Ausverkauft",
        },
        sideCart: {
          upsellProductIds: [
            "kraftiges-vollkornbrot",
            "viva-con-aqua-laut",
            "dip-balls",
            "lovechock-nuss-kuss-40-g-riegel",
            "lovechock-soul-karamell-meersalz-70-g-tafel",
            "spargel-feta",
          ],
          cartHeaderLabel: "Warenkorb",
          upsellHeaderLabel: "Darfs noch ein Extra sein?",
          soldOutLabel: "AUSVERKAUFT",
          totalPriceLabel: "Gesamtpreis",
          toMenuLabel: "Zum Menü",
          toCheckoutLabel: "Zur Kasse",
          hideUpsellLabel: "Ausblenden",
          showUpsellLabel: "Anzeigen",
        },
        productOverview: {
          categories: [{
            label: "Featured",
            id: "featured",
            productTags: ["category.top-seller"],
            excludeTags: ["product.configurable"]
          }, {
            label: "Bowls",
            id: "bowls",
            productTags: ["product.bowl"],
            excludeTags: ["product.configurable", "category.top-seller"]
          }, {
            label: "Create Your Own",
            id: "create-your-own",
            productTags: ["product.configurable"],
            excludeTags: ["category.top-seller"],
          }, {
            label: "Sides",
            id: "sides",
            productTags: ["category.side", "addon.bread"],
            excludeTags: ["category.top-seller"],
          }, {
            label: "Desserts",
            id: "desserts",
            productTags: ["category.dessert"],
            excludeTags: ["category.top-seller"],
          }, {
            label: "Drinks",
            id: "drinks",
            productTags: ["category.drink"],
            excludeTags: ["category.top-seller"],
          }]
        },
        productFilters: {
          filters: [
            {
              id: "vegan",
              label: "Vegan",
              tag: "vegan"
            },
            {
              id: "vegetarian",
              label: "Vegetarisch",
              tag: "vegetarian"
            },
            {
              id: "gluten-free",
              label: "Glutenfrei",
              tag: "glutenfree"
            },
            {
              id: "lactose-free",
              label: "Laktosefrei",
              tag: "lactosefree"
            }
          ]
        },
        productLabels: {
          labels: {
            soldOut: "Bald wieder verfügbar",
            suggested: "Empfehlung",
            seasonal: "Saisonal",
            budget: "Budget",
            chefsChoice: "Chef's Choice",
            foodlab: "Foodlab",
            highProtein: "High Protein",
            new: "Neu",
            recipe: "Neue Rezeptur",
            regional: "Regional",
            vegan: "Vegan",
            vegetarian: "Vegetarisch",
            variants: "Wähle dein Protein",
            warm: "Warmes Gericht"
          },
          noProductMatchingFilterMessage: "In dieser Kategorie passen keine Produkte zu deinen aktiven Filtern",
          resetFilterLabels: "Filter zurücksetzen",
          categoryEmptyMessage: "Diese Kategorie scheint aktuell leer zu sein"
        },
        lastCustoms: {
          labels: ["Deine letzte Bowl", "Deine vorletzte Bowl", "Deine Bowl"]
        },
        seasonColors: {
          primary: {
            background: "#dc5a00",
            text: "#ffffff"
          },
          medium: {
            background: "#ffe69b",
            text: "#dc5a00"
          },
          light: {
            background: "#fff0d2",
            text: "#dc5a00"
          }
        },
        storeBanner: {
          menuDownloadUrl: "https://assets.ctfassets.net/iudn81s4bj8w/2gUjl3izRXuihhWvZ4gHZs/ae7031ba379eae1f729a65cf95993678/20250326-spring-menu-online.pdf",
          bioLabel: "Bio-Zertifizierung - Kontrollstelle: DE-ÖKO-006",
          showDeliveryAreaLabel: "Liefergebiet anzeigen",
          deliveryStoreHeadline: "Oder einfach liefern lassen!",
          onSiteStoreHeadline: "Besuche uns in deiner Stadt.",
          weekDayLabel: "Mo-Fr",
          weekendLabel: "Sa+So",
          menuDownloadLabel: "Menü Download",
          dietaryInfoLabel: "Zusatzstoffe & Allergene",
          dietaryInfoUrl: "/allergene"
        }
      }
    },
    revalidate: 120,
  }
}) satisfies GetStaticProps<MenuPageProps>;
const MenuPage: FC<MenuPageProps> = (props) => {
  const {
    preFetchedProducts,
    preFetchedAvailableDeliveryGroups,
    activeDeliveryGroupId,
    pathDeliveryGroupId,
    cmsData
  } = props;
  const cityName = getCityNameFromPath(pathDeliveryGroupId);
  const title = `STADTSALAT${cityName !== "" ? ` ${cityName}` : ""}${cmsData.meta.title}`
  return (
    <Layout
      title={title}
      og={{
        title,
        description: cmsData.meta.description,
        type: "Website",
        url: `https://stadtsalat.de${!!pathDeliveryGroupId ? `/${pathDeliveryGroupId}` : ""}`,
        siteName: "STADTSALAT",
        image: "https://stadtsalat.de/assetsV2/images/og-image.jpg"
      }}
      seo={{description: cmsData.meta.description}}
      initializeState={({set}) => {
        set(CMSState, cmsData as unknown as TCMSState)
        set(StoreShiftState, getCurrentShift())
      }}
    >
      <MenuViewPage
        preFetchedProducts={preFetchedProducts}
        preFetchedAvailableDeliveryGroups={preFetchedAvailableDeliveryGroups}
        activeDeliveryGroupId={activeDeliveryGroupId}
        pathDeliveryGroupId={pathDeliveryGroupId}
      />
    </Layout>
  );
};
export default MenuPage;
