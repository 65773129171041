import { FC } from "react";

import useChat from "../../../../hooks/useChat";
import ChatBubbleIcon from "./icons/ChatBubbleIcon";
import { SupportIconProps } from "./SupportIcon.interfaces";
import { _IconWrapper, _SupportIconWrapper } from "./SupportIcon.styled";

export const SupportIconDumb: FC<SupportIconProps> = (props) => {
  const { onClick } = props;
  return (
    <_SupportIconWrapper className={"icon"} onClick={onClick}>
      <_IconWrapper>
        <ChatBubbleIcon width={"100%"} height={"100%"} />
      </_IconWrapper>
    </_SupportIconWrapper>
  );
};
const SupportIcon: FC = () => {
  const { openChat } = useChat();
  return <SupportIconDumb onClick={openChat} />;
};

export default SupportIcon;
